import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux'

import Header from './Header'
import OverlayMenu from './OverlayMenu'
import Hamburger from './Hamburger'
import Footer from './Footer'

import { LayoutWrapper, Primary } from "./styles/LayoutStyles"
import './layout.css'

// const mapStateToProps = ({ token }) => {
//   return { token }
// }
//
// const mapDispatchToProps = dispatch => {
//   return { token: () => dispatch({ type: `SET_TOKEN` }) }
// }

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOverlayMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const path = typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <LayoutWrapper path={path}>
      <Hamburger handleOverlayMenu={handleOverlayMenu} />
      <OverlayMenu menuOpen={menuOpen} callback={handleOverlayMenu} />
      <Header />
      <Primary id="primary" className="content-area">
        <main id="main" className="site-main" role="main">
          {children}
        </main>
      </Primary>
      <Footer />
    </LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
// export default connect(mapStateToProps, mapDispatchToProps)(Layout)
