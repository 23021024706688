import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  // faTwitterSquare,
  // faYoutubeSquare,
  // faLinkedinIn,
  // faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"

import { FooterWrapper, PhoneImg } from "./styles/FooterStyles"
import { format } from "date-fns"
import Phone from '../images/phone.png'

const year = format(new Date(), "yyyy")

const Footer = () => (
  <FooterWrapper>
    <div className="inner container container--narrow">
      <div className="group row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-one">
          <h1 className="school-logo-text school-logo-text--alt-color">
            <a href="/">
              <strong>SECTA</strong>
            </a>
          </h1>
          <div>Sue Jewel</div>
          <div className='phone'><PhoneImg src={Phone} alt="phone" /> 01579 320880</div>
          <div>Kim Spencer</div>
          <div className='phone'><PhoneImg src={Phone} alt="phone" /> 01503 240685</div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-two-three-group">
          <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-two">
            <h3 className="headline headline--small">Explore</h3>
            <nav className="nav-list">
              <ul className="min-list">
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/events">Events</a>
                </li>
                <li>
                  <a href="/news">News</a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-three">
            <h3 className="headline headline--small">Learn</h3>
            <nav className="nav-list">
              <ul className="min-list">
                <li>
                  <a href="/cookie-policy">Cookie Policy</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy</a>
                </li>
                <li>
                  {/*<a href={`${process.env.GATSBY_API_HOST}/terms-conditions`}>Terms & Conditions</a>*/}
                  <a href="/terms-conditions">Terms & Conditions</a>
                </li>
                <li>
                  <a href="/rules-and-codes-of-practise">Rules & Codes of Practise</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

        <div className="col-lg-3 col-md-3 col-sm-12 col-four">
          <h3 className="headline headline--small">Connect With Us</h3>
          <nav>
            <ul className="min-list social-icons-list group">
              <li>
                <a
                  href="https://www.facebook.com/sectacornwall"
                  className="social-color-facebook"
                >
                  <FontAwesomeIcon
                    icon={faFacebookSquare}
                    size={"2x"}
                    color={"#4862a3"}
                  />
                </a>
              </li>
              {/*<li>*/}
              {/*  <a*/}
              {/*    href="https://twitter.com/home"*/}
              {/*    className="social-color-twitter"*/}
              {/*  >*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon={faTwitterSquare}*/}
              {/*      size={"2x"}*/}
              {/*      color={"#55acee"}*/}
              {/*    />*/}
              {/*  </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a*/}
              {/*    href="https://www.youtube.com/"*/}
              {/*    className="social-color-youtube"*/}
              {/*  >*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon={faYoutubeSquare}*/}
              {/*      size={"2x"}*/}
              {/*      color={"#cc1e1f"}*/}
              {/*    />*/}
              {/*  </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a*/}
              {/*    href="https://www.linkedin.com/feed/"*/}
              {/*    className="social-color-linkedin"*/}
              {/*  >*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon={faLinkedinIn}*/}
              {/*      size={"2x"}*/}
              {/*      color={"#0077b5"}*/}
              {/*    />*/}
              {/*  </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a*/}
              {/*    href="https://www.instagram.com/?hl=en"*/}
              {/*    className="social-color-instagram"*/}
              {/*  >*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon={faInstagramSquare}*/}
              {/*      size={"2x"}*/}
              {/*      color={"#d8226b"}*/}
              {/*    />*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
          </nav>
        </div>
      </div>
      <div className="copywrite">
        <a href="https://moorsolutions.co.uk">Produced by Moor Solutions &copy; {year}</a>
      </div>
    </div>
  </FooterWrapper>
)

export default Footer
