import styled from "styled-components"

export const LoginOutWrapper = styled.li`
  button {
    text-align: center;
    background-color: Red;
    color: white;
    font-weight: bold;
    margin-top: -10px;
  }
`;
