import React from "react"
import { isLoggedIn, logout, getUsersName } from "../services/auth"
import { Link, navigate } from "gatsby"
import { LoginOutWrapper } from "./styles/LoginOutStyles"

const buttonClick = () => {
    navigate('/login')
}
const lnkLogout = () => {
    logout(() => navigate(`/login`))
}

const LoginOut = () => {
    return (
      <LoginOutWrapper>
          {isLoggedIn() ? (
            <>
                {getUsersName()}
                <ul>
                    <li>
                        <Link to={"/profile"} activeClassName="nav-active">Profile</Link></li>
                    <li>
                        <Link onClick={lnkLogout} to={'/login'} activeClassName="nav-active">Logout</Link>
                    </li>
                </ul>
            </>
          ) : (
            <button onClick={buttonClick}>LOGIN</button>
          )}
      </LoginOutWrapper>
    )
}

export default LoginOut;
