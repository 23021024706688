import styled from "styled-components"

export const FooterWrapper = styled.footer`
  padding: 2rem 0 3.5rem 0;
  background: linear-gradient(#fff, #ececec);

  h1 {
    font-family: "Open Sans", Arial;
  }

  h3 {
    font-family: "Open Sans", Arial;
    font-size: 24px;
    margin-bottom: 0px;
    font-weight: 200;
    letter-spacing: 1px;
  }

  p {
    font-size: 0.9rem;
  }
  .headline {
    color: #666;
    margin-bottom: 0.33rem;
  }
  .link {
    color: #999;
    text-decoration: none;
  }
  .link:hover {
    color: blue;
    text-decoration: underline;
  }
  .inner {
    border-top: 1px dotted #dedede;
    padding-top: 3.5rem;
  }
  .col-one {
    text-align: left;
    width: 33%;
    float: left;
    @media (max-width: 1000px) {
      text-align: center;
    }
  }
  .col-two-three-group {
    text-align: left;
    float: left;
    width: 42%;
    margin: 0;
    @media (max-width: 1000px) {
      text-align: center;
    }
  }
  .col-two {
    width: 50%;
    float: left;
  }
  .col-three {
    width: 50%;
    float: left;
  }
  .col-four {
    padding-top: 0;
    margin: 0;
    clear: none;
    text-align: left;
    width: 25%;
    float: right;
    @media (max-width: 1000px) {
      text-align: center;
    }
  }
  .min-list {
    padding: 0;
    margin: 0;
  }

  .min-list li {
    list-style: none;
  }

  .social-icons-list {
    display: flex;
    margin: 0 -8px;
  }

  .social-icons-list li {
    flex: 1;
    margin: 0 8px;
  }

  .social-icons-list li a {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 1.25rem;
  }

  .social-icons-list li a:hover {
    opacity: 0.75;
  }
  .copywrite {
    font-size: 0.8rem;
    text-align: center;
    background-color: #dddddd;
  }
  .phone {
    margin-top: -10px;
    img {
      margin-top: 10px;
     }
  }
`;
export const PhoneImg = styled.img`
  height: 20px;
`;

