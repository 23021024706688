import {useEffect, useState} from 'react'
import axios from "axios"
export const isBrowser = () => typeof window !== "undefined"

export const GetUser = () => {
  const [user, setUser] = useState('')
  const [loaded, setLoaded] = useState('false')

  useEffect(() => {
    const GetUser = async () => {
      try {
        if(isBrowser() && window.localStorage.getItem("gatsbyUser")){
          const usr = JSON.parse(window.localStorage.getItem("gatsbyUser"))
          setUser(usr)
          setLoaded('true')
        } else {
          return {}
        }
      } catch (err) { setLoaded('null')}
    }
    GetUser()
  }, [])
  return [user, loaded]
}

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser") ?
    JSON.parse(window.localStorage.getItem("gatsbyUser")) :
    {}


export const getUsersName = () => {
  if (isBrowser() && window.localStorage.getItem("gatsbyUser")) {
    const user = JSON.parse(window.localStorage.getItem("gatsbyUser"))
    return user.name
  } else {
    return ''
  }
}

export const GetUsersId = () => {
  const [id, setId] = useState('')
  const [available, setAvailable] = useState('false')

  useEffect(() => {
    const GetId = async () => {
      try {
        if (isBrowser() && window.localStorage.getItem("gatsbyUser")) {
          const user = JSON.parse(window.localStorage.getItem("gatsbyUser"))
          setId(user.id)
          setAvailable('true')
        } else {
          return ''
        }
      } catch (err) { setAvailable('null')}
    }
    GetId()
  }, [])
  return [id, available]
}

export const GetUsersEmail = () => {
  const [email, setEmail] = useState('')
  const [available, setAvailable] = useState('false')

  useEffect(() => {
    const GetEmail = async () => {
      try {
        if (isBrowser() && window.localStorage.getItem("gatsbyUser")) {
          const user = JSON.parse(window.localStorage.getItem("gatsbyUser"))
          setEmail(user.username)
          setAvailable('true')
        } else {
          return ''
        }
      } catch (err) { setAvailable('null')}
    }
    GetEmail()
  }, [])
  return [email, available]
}

export const getToken = () => {
  if(isBrowser() && window.localStorage.getItem("jwt") !== null){
    // return JSON.parse(window.localStorage.getItem("jwt"))
    return window.localStorage.getItem("jwt")
  }
  const tokenResp = axios({
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/aam/v2/authenticate`,
    data: {
      "username": `${process.env.GATSBY_API_ACCOUNT}`,
      "password": `${process.env.GATSBY_API_PASSWORD}`,
      "issueJWT": true
    }
  })
  Promise.all([tokenResp]).then(res => {
    const tkn = res[0].data.jwt.token
    window.localStorage.setItem("jwt", tkn.substr(0))
    return tkn
  })
}

export const validateToken = () => {
  if(isBrowser() && window.localStorage.getItem("jwt")) {
    const token = window.localStorage.getItem("jwt")
    const tokenResp = axios({
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/aam/v2/jwt/validate`,
      data: {
        "jwt": `${token}`
      }
    })
    Promise.all([tokenResp]).then(res => {
      if (!res[0].data.isValid) {
        getToken()
      }
    })
  } else {
    getToken()
  }
}

export const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

// export const handleLogin = ({ username, password }) => {
//   axios({
//     method: 'get',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authentication': 'Bearer ' + getToken()
//     },
//     url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member?filter[meta_key]=member_email&filter[meta_value]=${username}&filter[meta_key]=member_password&filter[meta_value]=${password}`,
//   })
//     .then(function (res) {
//       // handle success
//       if(res.data.length === 1){
//         setUser({
//           username: res.data[0].acf.member_email,
//           name: res.data[0].acf.member_name
//         })
//       } else {
//         alert('Email/password combination couldn\'t be found')
//       }
//     })
//     .catch(function (error) {
//       // handle error
//       console.log("error:", error);
//     })
// }

export const isLoggedIn = () => {
  // const [user, loaded] = GetUser()
  const user = getUser()
  return !!user.name
}

export const logout = callback => {
  setUser({})
  callback()
}
export const logoutNCB = () => {
  setUser({})
}
