import styled from "styled-components"

export const HeaderWrapper = styled.header`
  //background: #000;
  margin-bottom: 1.45rem;
  height: 80px;
  //border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;

  background-color: transparent !important;
  background-image: linear-gradient(
    180deg,
    #000000 0%,
    transparent 100%
  ) !important;

  &__avatar {
    position: absolute;
    top: 2px;
    left: 2px;
  }
  &__avatar img {
    display: block;
    width: 30px;
    height: 30px;
  }

  img {
    max-height: 90px;
    //margin: 40px 0 0 0; // set to 40
    //padding: 12px 0 0 0;
  }

  .menu {
    display: flex;
    align-items: center;
    color: white;
  }

  @media (max-width: 400px) {
    img {
      max-height: 60px;
    }
  }
`

export const HeaderAvatar = styled.div`
  padding-top: 40px;
`;
