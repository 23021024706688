import React from 'react';
// import { connect } from 'react-redux'
import { Link, useStaticQuery, graphql } from 'gatsby';

import Navigation from './Navigation';

import sectaLogo from '../images/SECTA-logo-new-white.png';

import { HeaderAvatar, HeaderWrapper } from "./styles/HeaderStyles"
import { isLoggedIn } from "../services/auth"

const Header = () => {
  const {
    site,
    menu: {
      edges: [{ node: menu }],
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      menu: allWordpressWpApiMenusMenusItems(
        filter: { slug: { eq: "main-menu" } }
      ) {
        totalCount
        edges {
          node {
            items {
              title
              url
              wordpress_children {
                title
                url
              }
            }
            name
          }
        }
      }
    }
  `);

  return (
    <HeaderWrapper>
      <div className="container">
        <div className="row">
          <HeaderAvatar className="col-md-3">
            <Link to="/">
              <img src={sectaLogo} alt={site.siteMetadata.title} />
            </Link>
          </HeaderAvatar>
          <div className="col-md-9 menu">
            <Navigation menu={menu} loggedIn={isLoggedIn()} />
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};
// const mapStateToProps = ({ loggedIn }) => {
//   return { loggedIn }
// }
// export default connect(mapStateToProps)(Header);
export default Header;
