import styled from "styled-components"

export const LayoutWrapper = styled.div`
  background-color: ${({ path }) => (
    path === '/about-us/your-team/' 
    ? '#444444' 
    : path === '/secta-application-form/' || path === '/secta-application-form' 
      ? '#008888' 
      : '#ffffff')};
  img {
    max-width: 100%;
    padding: 0;
    margin: 0 0 1.45rem;
  }
`;
export const Primary = styled.main`
  //padding: 110px 0 0 0;
  padding: 0px 0 0 0;
`

